import './App.scss';
import Page from './components/Page/page'


function App() {
  return (
    <>
    <Page/>
    </>
  );
}

export default App;
